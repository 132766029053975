import { Link } from 'gatsby'
import styled from 'styled-components'
import media from 'styled-media-query'
import { alvTokens } from '@dasa-health/alma-tokens'

export const MenuContainerMobile = styled.ul`
  overflow-x: hidden;
  position: absolute;
  width: 20.3rem;
  height: calc(100vh - 48px);
  padding: 2.4rem;
  margin: 0;
  top: -28px;
  right: 314px;
  font-size: 14px;
  background: var(--white);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: scale(0,1);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  z-index: 3000;

  li {
    padding: 1.6rem 0;
    border-bottom: 2px solid ${alvTokens.ColorNeutral20};

    &:first-child {
      padding: 0 0 1.6rem 0;
    }
  }
`

export const MenuMobileBg = styled.div`
  position: absolute;
  top: -16px;
  left: -20px;
  width: 100vh;
  height: 100vh;
  background-color: ${props => props.theme.colors.palette.black};
  opacity: 0.6;
  z-index: 500;
  display: ${props => (props.visible ? 'block' : 'none')};

  @media (min-width: 768px) and (max-width: 1170px) {
    left: 224px;
    width: 118vh;
  }
`

export const MenuMobileTitle = styled.li`
  font-size: 10px;
  font-weight: bold;
  color: ${props => props.theme.colors.brand.primary[10]};
`

export const MenuLinkMobile = styled(Link)`
  font-weight: 500;
  color: #012169;
  line-height: 115%;
  text-decoration: none;

  &.active {
    font-weight: 700;
  }
`

export const MenuLinkMobileCommon = styled.a`
  font-weight: 500;
  color: #012169;
  text-decoration: none;
`

export const MenuLinkResultsNav = styled.div`
  display: flex;
  padding: 1.6rem 0 0;
  color: #012169;
  font-weight: 500;

  a {
    &.nav-dasa {
      color: #012169;
      font-weight: 500;
      margin-right: 1rem;
    }

    .gatsby-image-wrapper {
      overflow: inherit !important;

      img {
        margin-top: -5px;
      }
    }
  }
`

export const SandwichContainer = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (min-width: 1171px) {
    display: none;
  }
`

export const MenuTitle = styled.p`
  font-weight: 500;
  font-size: 13px;
  margin: auto 0 auto .8rem;
  color: ${alvTokens.ColorPaletteGray30};
`

export const SocialLinks = styled.div`
    position: absolute;
    bottom: 24px;
`
export const SocialLinksHolder = styled.div``

export const SocialLinksListHolder = styled.ul`
  display: flex;
  justify-content: flex-start;
  padding: 0;

  ${media.lessThan('large')`
    li {
      padding: 0;
      margin-right: 2.4rem;
      border: none;

      &:first-child {
        padding: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  `}
`