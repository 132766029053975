import React, { useState, useEffect, Fragment } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as S from './styled'
import MenuMobileToggle from './MenuMobileToggle'
import LogoNavDasa from '../../shared/LogoNavDasa'
import SocialItens from './SocialItens'
import modalNav from '../../BannerNav/modalNav'

const MenuMobile = props => {
  const data = useStaticQuery(graphql`
    query {
      wordpressGeneoneThemeHeader {
        sidebars {
          widgets {
            name
            value {
              items {
                title
                url
                order
                object_id
              }
            }
          }
        }
      }
      modalResultsData: wordpressWpGatsbyPage(slug: {eq: "modal-results"}) {
        slug
        acf {
            results_modal_ativo
            results_modal_link
        }
      }
      modalMedicalChannelData: wordpressWpGatsbyPage(slug: {eq: "modal-medical-channel"}) {
        slug
        acf {
          medicalChannel_modal_ativo
          medicalChannel_modal_link
        }
      }
    }
  `)

  const {
    wordpressGeneoneThemeHeader: { sidebars },
  } = data

  const menuModalResults = data?.modalResultsData
  const menuModalMedicalChannel = data?.modalMedicalChannelData

  const [visible, setVisible] = useState(false)
  const [controlChecked, setControlChecked] = useState(false)

  const onInputChange = e => {
    toggleMenu(e.target.checked)
  }

  const handleBgClick = () => {
    toggleMenu(false)
  }

  const toggleMenu = state => {
    setControlChecked(state)
    setVisible(state)

    const body = document.querySelector('body')
    body.style.overflow = state ? 'hidden' : ''
    body.style.position = state ? 'relative' : ''

    if (state) {
      document.ontouchmove = e => e.preventDefault()
    } else {
      document.ontouchmove = null
    }
  }

  useEffect(() => {
    return clearScrollLock
  }, [])

  const clearScrollLock = () => {
    document.ontouchmove = null
    const body = document.querySelector('body')

    body.style.overflow = ''
    body.style.position = ''
  }

  return (
    <Fragment>
      <S.MenuMobileBg
        data-cy="header-menu-mobile-bg"
        visible={visible}
        onClick={handleBgClick.bind(this)}
      />
      <S.SandwichContainer>
        <MenuMobileToggle {...props} checked={controlChecked} onChange={onInputChange}>
          <S.MenuContainerMobile data-cy="header-menu-mobile-open">
            {sidebars[0].widgets[1].value.items.map(item => {
              if (item.url.match(/^https?:\/\//g)) {
                return (
                  <li key={item.order}>
                    <S.MenuLinkMobileCommon key={item.order} to={item.url} target="_blank">
                      {item.title}
                    </S.MenuLinkMobileCommon>
                  </li>
                )
              }

              return (
                <li key={item.order}>
                  <S.MenuLinkMobile
                    key={item.order}
                    to={item.url}
                    className={item.url === props.location.pathname ? 'active' : ''}
                  >
                    {item.title}
                  </S.MenuLinkMobile>
                </li>
              )
            })}

            {menuModalMedicalChannel.acf?.medicalChannel_modal_ativo && (
              <li 
                key={menuModalMedicalChannel.acf.medicalChannel_modal_link}
              >
                <div 
                  onClick={handleBgClick} 
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleBgClick()
                    }
                  }}
                  role="button" 
                  tabIndex="0"
                >
                  <S.MenuLinkMobile
                    key={menuModalMedicalChannel.acf.medicalChannel_modal_link}
                    to="#"
                    onClick={(e) => { modalNav(e, menuModalMedicalChannel.slug)}}
                  >
                    {menuModalMedicalChannel.acf.medicalChannel_modal_link}
                  </S.MenuLinkMobile>
                </div>
              </li>
            )}

            {menuModalResults.acf?.results_modal_ativo && (
              <li
                key={menuModalResults.acf.results_modal_link}
              >
                <S.MenuLinkResultsNav onClick={handleBgClick}>
                  <LogoNavDasa
                    menu={
                      {
                        url: '#', 
                        title: menuModalResults.acf.results_modal_link,
                        slug: menuModalResults.slug
                      }
                    }
                  />
                </S.MenuLinkResultsNav>
              </li>
            )}

            <li>
              <S.SocialLinks>
                <SocialItens size="16" />
              </S.SocialLinks>
            </li>
          </S.MenuContainerMobile>
        </MenuMobileToggle>
        <S.MenuTitle>
          Menu
        </S.MenuTitle>
      </S.SandwichContainer>
    </Fragment>
  )
}

export default MenuMobile
